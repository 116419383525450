<template>
    <div style="background-color: white;width: calc(100vw - 215px);height: calc(100vh - 50px);overflow-y: auto;">
        <div style="padding: 20px;box-sizing: border-box;max-width: 1460px;">
            <div class="top-container">
                <div class="topBg"></div>
                <div class="top-left-container">
                    <div :class="isValid ? 'content-container content-container-valid' : 'content-container'">
                        <p class="title">EAE Universal Pass</p>
                        <p class="name">Your Pass to Extracurricular Activity Excellence</p>
                        <p class="title" style="font-size: 22px;margin-top: 30px;">{{ user_name }}</p>
                        <p v-if="isValid == false" class="tag">Pending Activation</p>
                        <div v-if="isValid == true">
                            <p class="tag-valid">{{ startDate }} to {{ endDate }}</p>
                        </div>
                    </div>
                </div>
                <div class="top-right-container">
                    <p class="valid" @click="doSelectProduct" :style="{ cursor: isValid ? 'default' : 'pointer' }">{{
                        isValid
                            ? 'Valid' : 'Activate' }}</p>
                    <p class="text" v-if="isValid">Congratulations! You've unlocked access to all the
                        benefits of the EAE Universal Pass.
                        Enjoy the journey to extracurricular excellence!</p>
                    <p class="text" v-else>Unlock over 4000 SGD worth of benefits for only 980 SGD!
                        Subscribe now and seize the opportunity for unparalleled
                        extracurricular enrichment.</p>
                </div>
            </div>
            <p style="color: #333;font-size: 24px;font-weight: bold;margin-top: 30px;">EAE Universal Pass
                Benefits
                Package</p>
            <p style="color: #666;font-size: 18px;margin-top: 10px;line-height: 28px">EAE PASS
                offers a holistic
                approach to extracurricular empowerment， encompassing six key areas of benefits, with a value of over
                4000
                SGD per year.</p>
            <div class="package-container">
                <div v-for="(item, index) in packages" :key="index" class="item">
                    <el-popover placement="top-end" :title="item.name" width="340" trigger="hover"
                        :content="item.tip_text">
                        <img slot="reference" :src="item.icon"
                            style="width: 120px;height: auto;object-fit: cover;margin-top: 5px;">
                    </el-popover>
                    <p style="color: #333;font-size: 16px;text-align: center;word-wrap: break-word;margin-top: 5px;">{{
                        item.name }}
                    </p>
                </div>
            </div>
            <p style="color: #333;font-size: 24px;font-weight: bold;margin-top: 30px;">EAE Universal Pass
                Projects
                Portfolio（Continuously adding）</p>
            <p style="color: #666;font-size: 18px;margin-top: 10px;line-height: 28px;">
                Currently,
                the following projects are included in the EAE PASS. Subscribers have free and unlimited* access to the
                following projects throughout the subscription period.
                (Note: Some projects may have their own participation restrictions, which apply according to their
                individual rules.)</p>
            <div class="logo-container">
                <div v-for="(portfolio, index) in portfolioList" :key="index" class="logo-item">
                    <img :src="portfolio.logo_url" style="width: auto;height: 100%;object-fit: cover;"
                        @click="openprojectWeb(portfolio.link)">
                    <p style="color: #333;font-size: 10px;text-align: center;word-wrap: break-word;">{{ portfolio.name
                        }}</p>
                    <!-- <a :href="portfolio.link" target="_blank"> <img :src="portfolio.logo_url" class="logo-item"></a> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    getUPPortfolioList,
    getUniversalPassStatus
} from '../../api/eae'
import '../../assets/common/font.css'
import {
    getUserId,
    getUserName
} from '../../utils/store'
export default ({
    name: 'index',
    data() {
        return {
            user_name: getUserName(),
            startDate: '',
            endDate: '',
            packages: [
                {
                    name: 'Services',
                    icon: require('../../assets/image/up_res_service.svg'),
                    tip_text: 'Free participation in various specialized training and tutoring sessions offered by EAE. One-on-one guidance on extracurricular activities through a Q&A system.'
                },
                {
                    name: 'Resources',
                    icon: require('../../assets/image/up_res_resource.svg'),
                    tip_text: 'Free access to online courses, study materials, mock exam banks, activity planning resources, and other materials related to extracurricular learning and activities.'
                },
                {
                    name: 'Information',
                    icon: require('../../assets/image/up_res_info.svg'),
                    tip_text: 'Stay updated with the latest global news on extracurricular activities.'
                },
                {
                    name: 'Database',
                    icon: require('../../assets/image/up_res_database.svg'),
                    tip_text: "Free use of EAE's extracurricular activity database to discover high-quality programs and opportunities."
                },
                {
                    name: 'System',
                    icon: require('../../assets/image/up_res_system.svg'),
                    tip_text: "Free use of EAE's activity management system to record and track your extracurricular activities, creating a personal path to excellence."
                },
                {
                    name: 'Projects',
                    icon: require('../../assets/image/up_res_projects.svg'),
                    tip_text: "Free participation in all projects included in the EAE Portfolio. These range from international contests in various disciplines to Project-Based Learning (PBL) initiatives and accreditation programs."
                }
            ],
            isValid: false,
            portfolioList: []
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        openprojectWeb(url) {
            if (url) {
                window.open(url, '_blank')
            }
        },

        doSelectProduct() {
            if (this.isValid) {
                return
            }
            this.$router.push({
                path: '/home/select-product'
            })
        },

        fetchData() {

            getUPPortfolioList().then((res) => {
                this.portfolioList = res.data
            })

            //获取学生是否购买了up
            getUniversalPassStatus(getUserId()).then((res) => {
                let result = res.data
                if (result.code == 0) {
                    this.isValid = true
                    this.startDate = this.formatTimeToEng(result.data.create_time * 1000)
                    let expire_time = result.data.expire_time
                    let date = new Date(expire_time)
                    date.setHours(0, 0, 0, 0)
                    this.endDate = this.formatTimeToEng(date.getTime() - 1)
                } else if (result.code == -1) {
                    //过期
                    this.isValid = false
                } else {
                    this.isValid = false
                }
                // this.isValid = false
            })
        },

        formatTimeToEng(time) {
            var date = new Date(time);
            let year = date.getFullYear();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            return this.formatMonthToEng(month) + " " + day + ", " + year;
        },
        formatMonthToEng(month) {
            switch (month) {
                case 1:
                    return "January";
                case 2:
                    return "February";
                case 3:
                    return "March";
                case 4:
                    return "April";
                case 5:
                    return "May";
                case 6:
                    return "June";
                case 7:
                    return "July";
                case 8:
                    return "August";
                case 9:
                    return "September";
                case 10:
                    return "October";
                case 11:
                    return "November";
                case 12:
                    return "December";
            }
        },
    }
})
</script>
<style scoped lang="scss">
::-webkit-scrollbar {
    display: none;
}

.top-container {
    display: flex;
    height: 280px;
    position: relative;

    .topBg {
        position: absolute;
        left: 100px;
        right: 0px;
        bottom: 0px;
        height: 232px;
        background-color: #FFF4F3;
        border: 1px solid#EFEFEF;
    }

    .topBgValid {
        position: absolute;
        left: 100px;
        right: 0px;
        bottom: 0px;
        height: 232px;
        background-color: #FFF4F3;
        border: 1px solid#EFEFEF;
    }

    .top-left-container {
        border-radius: 5px;
        position: relative;
        box-sizing: border-box;
        border: 1px solid white;
        border-radius: 20px;
        padding: 2px;
        flex: 1.2;
        height: 100%;

        .content-container {
            border-radius: 20px;
            padding: 0px 30px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            background-image: url('../../assets/image/pending_activation.png');
            background-size: cover;
            background-position: 100%;
            background-repeat: no-repeat;
        }

        .content-container-valid {
            background-image: url('../../assets/image/valid.png');
        }

        .title {
            font-weight: bold;
            font-size: 32px;
            color: white;
        }

        .name {
            margin-top: 25px;
            font-size: 32px;
            color: white;
        }

        .tag {
            width: 200px;
            height: 36px;
            background-image: url('../../assets/image/up_tag_bg.png');
            background-size: cover;
            background-position: 100%;
            background-repeat: no-repeat;
            color: white;
            font-size: 18px;
            text-align: center;
            line-height: 36px;
            font-weight: bold;
            margin-top: 10px;
        }

        .tag-valid {
            margin-top: 10px;
            background-color: rgba(255, 255, 255, 0.3);
            display: inline-block;
            padding: 6px 12px;
            box-sizing: border-box;
            border-radius: 24px;
            color: white;
            font-size: 18px;
            text-align: center;
            font-weight: bold;
        }
    }

    .top-right-container {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0px 30px;
        box-sizing: border-box;

        .valid {
            cursor: pointer;
            border-radius: 5px;
            background-color: var(--c-primary);
            width: 180px;
            height: 56px;
            color: white;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            line-height: 56px;
        }

        .text {
            margin-top: 10px;
            font-size: 18px;
            line-height: 28px;
            color: #4A0700;
        }
    }
}

.package-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    column-gap: 15px;

    .item {
        width: 230px;
        height: 148px;
        text-align: center;
        color: white;
        font-size: 24px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}


.logo-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;

    .logo-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        background-color: #F8F8F8;
        padding: 15px 15px;
        box-sizing: border-box;
        width: 165px;
        height: 138px;
        object-fit: cover;
    }
}
</style>
